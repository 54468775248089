<template>
  <div class="Tests">
    <span class="PageHeader">Администрирование</span>

    <span class="FiltersHeader">Заявки на исполнение задач от студентов</span>

    <div class="Filters">
      <div class="FiltersBlock noselect">
        <div class="StatusesBlock">
          <span
            :class="{
              selected: s === Filters.Status,
            }"
            v-for="(s, sindx) in Statuses"
            :key="s + sindx"
            @click="
              Filters.Status == s
                ? (Filters.Status = null)
                : (Filters.Status = s)
            "
            >{{ s }}</span
          >
        </div>
        <div class="SortBy">
          <select class="SelectInput" v-model="Filters.SortBy">
            <option
              v-for="(f, findx) in SortsList"
              :key="f + findx"
              :value="f"
              >{{ f }}</option
            >
          </select>

          <span class="ResetFilters" @click="clearFilters"
            >Сбросить фильтры</span
          >
        </div>
      </div>

      <input
        class="SearchInput"
        type="text"
        v-model="Filters.SearchInput"
        placeholder="Например: Иванов Иван"
      />
    </div>

    <div class="ReqCards">
      <div
        v-for="(rcard, rcindx) in getSortedList"
        :class="{
          Card: true,
          Selected: rcard.req_id === SelectedReq?.req_id,
        }"
        :key="rcindx + rcard"
        @click="
          () => {
            SelectedReq = rcard;
            showModal = true;
          }
        "
      >
        <span class="CDate">{{ getNormalDate(rcard?.createdAt) }}</span>
        <span class="CFIO">{{
          getFIO(
            rcard?.user_data?.surname,
            rcard?.user_data?.name,
            rcard?.user_data?.patronymic
          )
        }}</span>
        <span class="CInfo">{{
          getShortDesc(
            rcard?.user_data?.MapRegion,
            getSections(rcard?.job_data?.sections)
          )
        }}</span>
        <span
          :class="{
            CStatus: true,
            IsWaiting: rcard?.status === 'Ожидает рассмотрения',
            IsAccepted: rcard?.status === 'Принята',
            IsDeclined:
              rcard?.status === 'Отклонена' ||
              rcard?.status === 'Отменена пользователем',
          }"
          >{{ rcard?.status }}</span
        >
      </div>
    </div>

    <div class="ModalWrapper" v-show="showModal">
      <div class="SendRequestModal">
        <div
          class="Close"
          @click="
            () => {
              showModal = false;
              SelectedReq = null;
              showModalStep = 1;
              showModalComment = '';
            }
          "
        ></div>

        <template v-if="showModalStep === 1">
          <div class="Title">
            <span
              >Заявка студента
              <span
                v-if="
                  !SelectedReq?.job_data?.repeatable &&
                    taskHaveActualWorker(
                      SelectedReq?.job_data?.JobWorkersList
                    ) != 0
                "
                style="color: #e82f2f; font-size: 12px;"
                >(ЗАДАЧА УЖЕ ЗАНЯТА)</span
              ></span
            >
          </div>

          <div class="ReqInfo">
            <div class="RTop">
              <span class="RFIO">{{
                getFIO(
                  SelectedReq?.user_data?.surname,
                  SelectedReq?.user_data?.name,
                  SelectedReq?.user_data?.patronymic
                )
              }}</span>
              <span class="RInfo"
                >{{ getFullDesc(SelectedReq?.user_data) }},
                <a
                  v-if="
                    SelectedReq?.user_data?.tg_nick != null &&
                      SelectedReq?.user_data?.tg_nick != ''
                  "
                  :href="`https://t.me/${SelectedReq?.user_data?.tg_nick}`"
                  >@TELEGRAM</a
                ></span
              >
            </div>
            <div class="RBot">
              <span>{{ getFullJob(SelectedReq?.job_data) }}</span>
              <span
                class="ql-editor"
                style="max-height: 100px; overflow: auto; width: 100%;"
                v-html="SelectedReq?.job_data?.description"
              ></span>
              <span>{{ getFullJobDesc(SelectedReq?.job_data) }}</span>
            </div>
          </div>

          <div class="ReqButtons noselect">
            <ButtonGradient
              v-if="
                SelectedReq?.job_data?.repeatable ||
                  (!SelectedReq?.job_data?.repeatable &&
                    taskHaveActualWorker(
                      SelectedReq?.job_data?.JobWorkersList
                    ) == 0)
              "
              @click.native="acceptReq"
              :key="
                SelectedReq?.job_data?.id +
                  SelectedReq?.job_data?.repeatable +
                  taskHaveActualWorker(SelectedReq?.job_data?.JobWorkersList)
              "
              style="width: 100%; flex-shrink: 1; flex-grow: 1;"
              ButtonText="Одобрить"
            />
            <span class="CustomButtonDecline" @click="showModalStep = 2"
              >Отклонить</span
            >
          </div>
        </template>
        <template v-else>
          <div class="Title">
            <span>Оставьте комментарий</span>
          </div>

          <div
            class="QEditor QEditorFixHeight"
            style="position: relative; width: 100%; height: 400px;"
          >
            <QuillEditor
              ref="QuillEditor"
              toolbar="full"
              theme="snow"
              contentType="html"
              :content="showModalComment"
              @textChange="handleEditorEditChanges($event)"
              @ready="handleEditorEditReady()"
            />
          </div>

          <div class="ReqButtons noselect">
            <ButtonGradient
              @click.native="declineReq"
              style="width: 100%; flex-shrink: 1; flex-grow: 1;"
              ButtonText="Отклонить"
            />
            <span
              class="CustomButtonDecline"
              @click="
                () => {
                  showModal = false;
                  SelectedReq = null;
                  showModalStep = 1;
                  showModalComment = '';
                }
              "
              >Отмена</span
            >
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllJobCandidateReq,
  createJobCandidateReq,
} from "@/api/job_candidate_request.js";

import { QuillEditor } from "@vueup/vue-quill";

import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";

export default {
  components: { ButtonGradient, QuillEditor },
  data() {
    return {
      Statuses: [
        "Ожидает рассмотрения",
        "Принята",
        "Отклонена",
        "Отменена пользователем",
      ],
      SortsList: [
        "Сортировка по умолчанию (Новее)",
        "По дате (Старее)",
        "По региону (А-я)",
        "По региону (Я-а)",
        "По вузу (А-я)",
        "По вузу (Я-а)",
        "По cтатусу (При-Ожи-Отк)",
        "По cтатусу (Отк-Ожи-При)",
      ],
      Filters: {
        Status: null,
        SortBy: "Сортировка по умолчанию (Новее)",
        SearchInput: "",
      },

      RequestsList: [],

      SelectedReq: null,

      showModal: false,
      showModalStep: 1,
      showModalComment: "",
    };
  },
  async created() {
    await this.initialization();
  },
  watch: {},
  computed: {
    getFilteredList() {
      let list = [...this.RequestsList];

      if (this.Filters.Status != null) {
        list = list.filter((x) => x.status === this.Filters.Status);
      }

      if (this.Filters.SearchInput != "") {
        list = list.filter((x) => {
          let incFio = this.getFIO(
            x?.user_data?.surname,
            x?.user_data?.name,
            x?.user_data?.patronymic
          );
          incFio = incFio
            .toLowerCase()
            .includes(this.Filters.SearchInput.toLowerCase());

          let incDate = this.getNormalDate(x?.createdAt);
          incDate = `${incDate}`
            .toLowerCase()
            .includes(this.Filters.SearchInput.toLowerCase());

          let incShortInfo = this.getShortDesc(
            x?.user_data?.MapRegion,
            this.getSections(x?.job_data?.sections)
          );
          incShortInfo = incShortInfo
            .toLowerCase()
            .includes(this.Filters.SearchInput.toLowerCase());

          let incFullInfo = this.getFullDesc(x?.user_data);
          incFullInfo = incFullInfo
            .toLowerCase()
            .includes(this.Filters.SearchInput.toLowerCase());

          let incTelegramm = x?.user_data?.tg_nick;
          incTelegramm = incTelegramm
            .toLowerCase()
            .includes(this.Filters.SearchInput.toLowerCase());

          let incJobName = this.getFullJob(x?.job_data);
          incJobName = incJobName
            .toLowerCase()
            .includes(this.Filters.SearchInput.toLowerCase());

          return (
            incFio ||
            incDate ||
            incShortInfo ||
            incFullInfo ||
            incTelegramm ||
            incJobName
          );
        });
      }

      return list;
    },
    getSortedList() {
      let list = [...this.getFilteredList];

      if (this.Filters.SortBy === "Сортировка по умолчанию (Новее)") {
        list = list.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateB - dateA; // Старые даты будут впереди
        });

        return list;
      }

      if (this.Filters.SortBy === "По дате (Старее)") {
        list = list.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateA - dateB; // Старые даты будут впереди
        });

        return list;
      }
      if (this.Filters.SortBy === "По дате (Старее)") {
        list = list.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateA - dateB; // Старые даты будут впереди
        });

        return list;
      }

      if (this.Filters.SortBy === "По региону (А-я)") {
        list = list.sort((a, b) => {
          const cityA = this.getCity(a?.user_data?.MapRegion).toLowerCase();
          const cityB = this.getCity(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = cityA.charCodeAt(0);
          const firstCharB = cityB.charCodeAt(0);

          return firstCharA - firstCharB;
        });

        return list;
      }
      if (this.Filters.SortBy === "По региону (Я-а)") {
        list = list.sort((a, b) => {
          const cityA = this.getCity(a?.user_data?.MapRegion).toLowerCase();
          const cityB = this.getCity(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = cityA.charCodeAt(0);
          const firstCharB = cityB.charCodeAt(0);

          return firstCharB - firstCharA;
        });

        return list;
      }

      if (this.Filters.SortBy === "По вузу (А-я)") {
        list = list.sort((a, b) => {
          const univerA = this.getUniver(a?.user_data?.MapRegion).toLowerCase();
          const univerB = this.getUniver(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = univerA.charCodeAt(0);
          const firstCharB = univerB.charCodeAt(0);

          return firstCharA - firstCharB;
        });

        return list;
      }
      if (this.Filters.SortBy === "По вузу (Я-а)") {
        list = list.sort((a, b) => {
          const univerA = this.getUniver(a?.user_data?.MapRegion).toLowerCase();
          const univerB = this.getUniver(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = univerA.charCodeAt(0);
          const firstCharB = univerB.charCodeAt(0);

          return firstCharB - firstCharA;
        });

        return list;
      }

      if (this.Filters.SortBy === "По cтатусу (При-Ожи-Отк)") {
        const statusOrder = {
          Принята: 1,
          "Ожидает рассмотрения": 2,
          Отклонена: 3,
          "Отменена пользователем": 3,
        };

        list = list.sort(
          (a, b) => statusOrder[a.status] - statusOrder[b.status]
        );

        return list;
      }
      if (this.Filters.SortBy === "По cтатусу (Отк-Ожи-При)") {
        const statusOrder = {
          Принята: 3,
          "Ожидает рассмотрения": 2,
          Отклонена: 1,
          "Отменена пользователем": 1,
        };

        list = list.sort(
          (a, b) => statusOrder[a.status] - statusOrder[b.status]
        );

        return list;
      }

      return list;
    },
  },
  methods: {
    getSections(sects) {
      if (sects == null) {
        return "";
      }

      return sects.map((x) => x.name).join(", ");
    },
    handleEditorEditChanges(delta) {
      this.showModalComment = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.showModalComment);
    },

    taskHaveActualWorker(workersList) {
      if (workersList == null || workersList?.length === 0) {
        return 0;
      }

      return workersList.filter((x) => x?.jobs_worker?.status === "В работе")
        .length;
      // return workersList?.jobs_worker;
    },

    async initialization() {
      let _jobReqList = await getAllJobCandidateReq();
      this.RequestsList = _jobReqList.data;
    },

    getCity(MapRegion) {
      let City = "";

      let MapUniver = MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        City = MapUniver.UsersChoosedUniver.region.name;
        City = City == "Другое" ? MapUniver.region_other : City;
      }

      return City;
    },
    getUniver(MapRegion) {
      let Univer = "";

      let MapUniver = MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        Univer = MapUniver.UsersChoosedUniver.name;
        Univer = Univer == "Другое" ? MapUniver.univer_other : Univer;
      }

      return Univer;
    },

    getNormalDate(BDDate) {
      let date = BDDate.split("T")[0].split("-");
      return `${date[2]}.${date[1]}.${date[0]}`;
    },
    getFIO(F, I, O) {
      if (F == null) {
        F = "";
      }
      if (I == null) {
        I = "";
      }
      if (O == null) {
        O = "";
      }
      return `${F} ${I} ${O}`;
    },
    getShortDesc(MapRegion, section_name) {
      let Univer = "";
      let City = "";

      let MapUniver = MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        City = MapUniver.UsersChoosedUniver.region.name;
        City = City == "Другое" ? MapUniver.region_other : City;

        Univer = MapUniver.UsersChoosedUniver.name;
        Univer = Univer == "Другое" ? MapUniver.univer_other : Univer;
      }

      let UniverCityRes = [Univer, City, section_name];
      UniverCityRes = UniverCityRes.filter((x) => x != null && x != "");

      return UniverCityRes.join(", ");
    },
    getFullDesc(user_data) {
      let Univer = "";
      let City = "";

      let MapUniver = user_data?.MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        City = MapUniver.UsersChoosedUniver.region.name;
        City = City == "Другое" ? MapUniver.region_other : City;

        Univer = MapUniver.UsersChoosedUniver.name;
        Univer = Univer == "Другое" ? MapUniver.univer_other : Univer;
      }

      let UniverCityRes = [Univer, City, user_data?.tel];
      UniverCityRes = UniverCityRes.filter((x) => x != null && x != "");

      return UniverCityRes.join(", ");
    },

    getFullJob(job_data) {
      return `${job_data?.name} (Проект: ${
        job_data?.project == null
          ? job_data?.n_project == null
            ? "Проект был удален"
            : job_data?.n_project?.name
          : job_data?.project?.name
      })`;
    },
    getFullJobDesc(job_data) {
      let JobDesc = [
        this.getSections(job_data?.sections),
        job_data?.repeatable ? "типовая" : "",
      ];
      JobDesc = JobDesc.filter((x) => x != null && x != "");
      return JobDesc.join(", ");
    },

    async acceptReq() {
      await createJobCandidateReq({
        action: "adminChange",
        jobID: this.SelectedReq.JobId,
        userID: this.SelectedReq.userId,
        status: "Принята",
        comment: "Принято",
      }).then(async (res) => {
        await this.initialization();
        this.showModal = false;
      });
    },
    async declineReq() {
      await createJobCandidateReq({
        action: "adminChange",
        jobID: this.SelectedReq.JobId,
        userID: this.SelectedReq.userId,
        status: "Отклонена",
        comment: this.showModalComment == null ? "" : this.showModalComment,
      }).then(async (res) => {
        await this.initialization();
        this.showModal = false;
        this.SelectedReq = null;
        this.showModalStep = 1;
        this.showModalComment = "";
      });
    },

    clearFilters() {
      this.Filters = {
        Status: null,
        SortBy: "Сортировка по умолчанию (Новее)",
        SearchInput: "",
      };
    },
  },
};
</script>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.CustomButtonDecline {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  background-color: #656bff40;

  flex-shrink: 0;
}

.ModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: 500;
}

.SendRequestModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;

  width: fit-content;
  min-width: 464px;
  height: fit-content;

  max-width: 100%;
  max-height: 98%;

  overflow-y: auto;

  padding: 20px;
  margin: auto;

  background: linear-gradient(
    224.99deg,
    #1e1b4a 0.05%,
    #1f1c4a 48.65%,
    #31337b 100.05%
  );
  border-radius: 8px;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.SendRequestModal::-webkit-scrollbar {
  width: 0px;
}
.SendRequestModal::-webkit-scrollbar-track {
  background: transparent;
}
.SendRequestModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.SendRequestModal textarea {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
  min-height: 48px;

  padding: 12px 8px;
  border-radius: 4px;
  border: 1px solid #d3caff40;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  background-color: transparent;
}

.Close {
  position: absolute;
  display: block;

  top: 0;
  right: 0;

  width: 32px;
  height: 32px;

  background: url("./../../assets/img/Landing/CloseButtonForRegModal.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  border-radius: 4px;

  background-color: #656bff40;

  z-index: 10;
}
.Title {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 5;
}
.Title > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-right: 195px;
}

.ReqInfo {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.RTop,
.RBot {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.RFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.RInfo {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caffb2;
}
.RInfo > a {
  position: relative;
  display: block;

  padding-left: 4px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  text-decoration: underline;
  color: #656bff;
}
.RBot > span:nth-child(1) {
  position: relative;
  display: block;

  width: fit-content;
  max-width: 464px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  text-decoration: none;
  color: #656bff;
}
.RBot > span:nth-child(2) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffffd4;
  max-width: 500px;
}
.RBot > span:nth-child(3) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caffb2;
}

.ReqButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
</style>

<style scoped>
.Card {
  position: relative;
  display: flex;

  width: 23.5%;

  height: auto;

  flex-basis: auto;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  transition: all 0.2s ease-out;

  border-radius: 12px;

  background: url("./../../assets/img/Admin/LogoBG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #1e1b4a;
}
.Card:hover {
  cursor: pointer;
  background: none;
  background-color: #27235c;
}
.Card.Selected {
  background: none;
  background-color: #27235c;
}
.CDate {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-bottom: 4px;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caff4d;
}
.CFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #fff;
}
.CInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #d3caffb2;
}
.CStatus {
  position: relative;
  display: block;

  padding: 4px 8px;
  margin-top: 52px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}

.IsWaiting {
  color: #f9dc5c;
  border: 1px solid #f9dc5c;
}
.IsAccepted {
  color: #25ed69;
  border: 1px solid #25ed69;
}
.IsDeclined {
  color: #ed254e;
  border: 1px solid #ed254e;
}

.ReqCards {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px 2%;
}

.Tests {
  position: relative;

  padding-top: 60px;
  padding-left: 156px;
  padding-right: 156px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}

.PageHeader {
  position: relative;
  display: block;

  margin-bottom: 20px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
}
.FiltersHeader {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.Filters {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  width: 100%;
  height: fit-content;
}
.FiltersBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock,
.SortBy {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock > span {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;
}
.StatusesBlock > span.selected,
.StatusesBlock > span:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.SearchInput {
  position: relative;
  display: block;

  padding: 12px 16px;

  width: 378px;
  height: fit-content;

  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #fff;
}
.SearchInput::placeholder,
.SearchInput::-ms-input-placeholder {
  color: #d3caff73;
}

.SelectInput {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 3.5em 0.5em 1em;
  padding-right: 45px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}
.SelectInput::-ms-expand {
  display: none;
}
.SelectInput:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}
.SelectInput:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.ResetFilters {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 1em 0.5em 1em;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #656bff;
  transition: all 0.2s ease-out;
}
.ResetFilters:hover {
  background-color: transparent;
  border: 1px solid #656bff;
}
</style>
